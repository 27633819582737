// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//apiUrl:       "@@APP_API_URI",

export const environment = {
    production:   true,
    apiUrl: "https://api.irrimeter.com",
    googleMapKey: "AIzaSyCon8Fa6t29s0ZrErWqrWL2eVVYPw8wXqQ",
    gaId:         "@@APP_GOOGLE_ANALYTICS_ID",
};
