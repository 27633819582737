import {Component, EventEmitter, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import * as Highcharts from 'highcharts';
import {setTheme} from 'ngx-bootstrap/utils';
import {combineLatest} from "rxjs";
import {filter, map} from "rxjs/operators";
import {environment} from "../environments/environment.var";
import {AppStartupService} from "./app-startup.service";
import {LoggerService} from "./core/logger.service";


declare var gtag;


@Component({
    selector:    'app-root',
    templateUrl: './app.component.html',
    styleUrls:   ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private startupSrv: AppStartupService,
                private router: Router,
                private l: LoggerService) {
        setTheme('bs4');
    }

    ngOnInit(): void {
        Highcharts.setOptions({
            global: {useUTC: false}
        });

	/*if (!this.startupSrv.data) {
            this.router.navigate(['/auth/404'], {queryParams: {reason: 'invalidorg'}});
            document.title = '404 - Not Found';
            return;
	    }*/

        this.setWebAppTitle();
        this.gaIntegration();
    }

    setWebAppTitle() {
        document.title = this.startupSrv.data.name;
    }

    private gaIntegration() {
        if (!environment.gaId) {
            this.l.debug('GA id not available');
            return;
        }

        const isLoaded$ = new EventEmitter<void>();

        // setting up the GA script
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaId;
        document.head.prepend(script);

        script.addEventListener('load', () => {
            this.l.debug('GA loaded script');

            ﻿window['dataLayer'] = window['dataLayer'] || [];
            window['gtag'] = function () {
                window['dataLayer'].push(arguments);
            };

            gtag('js', new Date());

            isLoaded$.emit();
        });

        const routeChange$ = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd));

        combineLatest(isLoaded$, routeChange$)
            .pipe(map(values => values[1]))
            .subscribe(
                (e: NavigationEnd) => gtag('config', environment.gaId, {'page_path': e.urlAfterRedirects})
            );
    }

}
