import {Component, Input} from "@angular/core";
import {FormGroupDirective, NgForm} from "@angular/forms";


@Component({
    template: `
                  <button [ladda]="ladda"
                          [attr.class]="btnClass"
                          type="button"
                          mwlConfirmationPopover
                          [isDisabled]="this.formDir.form.get('organization').value"
                          [popoverTitle]="'Empty organization'"
                          [popoverMessage]="'Are you sure you want to save the user without' +
                                     'an organization?'"
                          (confirm)="ngForm.ngSubmit.emit()"
                          (click)="submitForm()"
                          [innerHTML]="content">
                  </button>`,
    selector: 'app-organization-submit-btn'
})
export class OrganizationSubmitBtn {
    @Input() ladda: string;
    @Input() btnClass: string;
    @Input() ngForm: NgForm;
    @Input() content: string;

    constructor(public formDir: FormGroupDirective) {

    }

    submitForm() {
        if (this.formDir.form.get('organization').value) {
            this.ngForm.ngSubmit.emit();
        }
    }
}
