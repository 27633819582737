import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroupDirective} from "@angular/forms";
import {Observable, of} from "rxjs";
import {catchError, map, shareReplay, tap} from "rxjs/operators";
import {ApiSdkService} from "../../../core/api-sdk/api-sdk.service";
import {Organization} from "../../../core/api-sdk/organization/organization.interface";


@Component({
    selector:      'app-organization-form-control',
    templateUrl:   './organization-form-control.component.html',
    styleUrls:     ['./organization-form-control.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrganizationFormControlComponent implements OnInit {
    @Input() placeholder = 'No organization';
    @Input() bindValue: string;

    isLoading = false;
    organization$: Observable<Organization[]>;

    constructor(private api: ApiSdkService,
                public f: FormGroupDirective) {
    }

    ngOnInit() {
        this.initOrganizationLookup()
    }

    private initOrganizationLookup() {
        this.organization$ = this.api.organization.list({fields: 'id,name', page_size: 100})
            .pipe(
                tap(() => this.isLoading = true),
                map(resp => resp.body),
                catchError(() => of([])), // empty list on error
                tap(() => this.isLoading = false),
                shareReplay(1)
            )
    }

}
