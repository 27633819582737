import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {ToastrService} from "ngx-toastr";
import {Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, takeUntil, tap} from "rxjs/operators";
import {hasOwnProperty} from "tslint/lib/utils";
import {ApiListItem} from "../../../core/api-sdk/api-sdk.interface";
import {ExComponent} from "../../../core/classes/ex-component";
import {LoggerService} from "../../../core/logger.service";
import Utils from "../../../core/utils";
import {Column, DatatableOption} from "./datatable.interface";


@Component({
    selector:    'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls:   ['./datatable.component.scss'],
})
export class DatatableComponent extends ExComponent implements OnInit {
    @Input() options: DatatableOption;

    data: ApiListItem<any>;
    ui: any;
    page: number = 1;
    searchQuery: string;
    pageSize: number;
    orderBy: string;

    public filterForm: FormGroup;
    public searchModelChanged: Subject<string> = new Subject();

    constructor(private l: LoggerService,
                private router: Router,
                private t: ToastrService,
                private fb: FormBuilder) {
        super();
    }

    get columnCount() {
        return _.filter(this.options.columns, (item) => item.isVisible != false).length + 1;
    }

    ngOnInit() {
        this.ui = {isLoading: false, isFilterLoading: false};
        this.pageSize = this.options.defaultPageSize;
        this.options = _.merge({actions: this.defaultActions}, this.options);
        this.setColumnName();
        this.initFilterForm();
        this.loadData();

        this.searchModelChanged.pipe(
            takeUntil(this.unsubscribe),
            distinctUntilChanged(),
            debounceTime(1000),
            tap(() => this.loadData())
        ).subscribe()
    }

    loadData(page: number = 1) {
        const oldPage = this.page;
        const qp = {
            page_size: this.pageSize,
            page:      page,
            search:    this.searchQuery,
            order_by:  this.orderBy
        };

        // Applying filter
        _.merge(qp, _.get(this.filterForm, 'value'));

        // Appending filter to the query
        this.ui.isLoading = true;

        this.options.loadData(qp)
            .subscribe(
                resp => {
                    this.page = page;
                    this.data = ({items: resp.body, meta: resp['meta']});
                    this.ui.isLoading = false;

                },
                e => {
                    this.page = oldPage;
                    this.l.debug('list error', e);
                    if (e.error.error.type != 'ValidationError')
                        this.t.error(e.error.error.detail);
                    this.ui.isLoading = false;
                    this.data = ({items: [], meta: {count: 0, from: 0, to: 0}});
                }
            )
    }

    getColumnValue(column: Column, item) {
        if (column.dataKey instanceof Function) {
            return column.dataKey(item)
        } else {
            return _.get(item, column.dataKey as string);
        }
    }

    getColumnByTitle(columns: [], title: string) {
        if (!columns || columns.length === 0) {
            return null;
        }

        return _.find(columns, {title: title});
    }

    getColumnType(column: Column, item) {
        const val = this.getColumnValue(column, item);

        if (val instanceof Observable) {
            return 'async';
        } else if (val && hasOwnProperty(val, 'component')) {
            return 'component';
        } else if (val && !!val.createEmbeddedView) {
            return 'container';
        } else {
            return 'sync';
        }
    }

    tableStateChange(e) {
        this.l.debug('Table state change', e);

        if (e.hasOwnProperty('sortBy')) {
            this.orderBy = e.sortBy;
            this.loadData(1);
        }
    }

    filter() {
        this.loadData();
    }

    private setColumnName() {
        this.options.columns.forEach(column => {
            if (!column._name)
                column._name = Utils.slugify(column.title);
        })
    }

    private initFilterForm() {
        if (!this.options.filters || this.options.filters.length < 1) return;

        const controlsConfig = {};

        this.options.filters.forEach(filter => {
            controlsConfig[filter.filterKey] = [filter.default];
        });

        this.filterForm = this.fb.group(controlsConfig);

    }

    readonly defaultActions = {
        edit: true,
        view: false
    };
}
