import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {User} from "./api-sdk/user/user.interface";


@Injectable({providedIn: 'root'})
export class UserService {
    detail: BehaviorSubject<User>;

    constructor(private t: ToastrService) {
        this.detail = new BehaviorSubject<any>(this.getDetailFromStorage());
    }

    get isAnonymous(): boolean {
        return _.isEmpty(this.detail.value);
    }

    get isAdminOrStaff(): boolean {
        return !_.isEmpty(this.detail.value) && this.detail.value.is_staff_or_admin;
    }

    get isAdmin(): boolean {
        return !_.isEmpty(this.detail.value) && this.detail.value.is_superuser;
    }

    get isStaff(): boolean {
        return !_.isEmpty(this.detail.value) && this.detail.value.is_staff;
    }

    get isOrganizationStaffOrAdmin(): boolean {
        return !_.isEmpty(this.detail.value)
            && this.detail.value.is_staff_or_admin
            && !this.detail.value.is_system_staff_or_admin;
    }

    get isOrganizationStaff(): boolean {
        return !_.isEmpty(this.detail.value)
            && this.detail.value.is_staff
            && !this.detail.value.is_system_staff_or_admin;
    }

    get isOrganizationAdmin(): boolean {
        return !_.isEmpty(this.detail.value)
            && this.detail.value.is_superuser
            && !this.detail.value.is_system_staff_or_admin;
    }

    get isSystemStaffOrAdmin(): boolean {
        return !_.isEmpty(this.detail.value) && this.detail.value.is_system_staff_or_admin;
    }

    get isSystemAdmin(): boolean {
        return !_.isEmpty(this.detail.value)
            && this.detail.value.is_superuser
            && this.detail.value.organization == null;
    }

    get isMasquerading(): boolean {
        return !_.isEmpty(_.get(this, 'detail.value.original'));
    }

    setUser(user: User, forSessionOnly = true) {
        if (this.getDetailFromStorage() !== null)
            throw new Error('User is already set. Please call clear() and the set again.');

        this._setUser(user, forSessionOnly);
    }

    update(value: any) {
        let newVal = <User>_.merge(this.getDetailFromStorage(), value);
        this._setUser(newVal, newVal['forSessionOnly']);
    }

    clear() {
        sessionStorage.removeItem('u');
        localStorage.removeItem('u');

        this.detail.next(this.getDetailFromStorage());
    }

    hasPerm(regexPermissionStr: string) {
        const idx = _.findIndex(this.detail.value.permissions, item => {
            const re = new RegExp(regexPermissionStr, 'ig');

            return re.test(item);
        });

        return idx > -1;
    }

    masquerade(user: User) {
        if (this.isMasquerading) {
            this.t.error('Youre are already impersonating. Nested impersonation is not supported.');
            return;
        }
        user['original'] = this.detail.value;

        this._setUser(user, this.detail.value['forSessionOnly']);
    }

    exitMasquerade() {
        if (this.isMasquerading === true) {
            this._setUser(this.detail.value['original'],
                this.detail.value['original']['forSessionOnly']);
        }
    }

    private getDetailFromStorage(): User {
        let u = sessionStorage.getItem('u');
        u = _.isEmpty(u) ? localStorage.getItem('u') : u;

        return _.isEmpty(u) ? null : <User>JSON.parse(u);
    }

    private _setUser(user: User, forSessionOnly) {
        let storage = forSessionOnly === true ? sessionStorage : localStorage;
        user['forSessionOnly'] = forSessionOnly;
        storage.setItem('u', JSON.stringify(user));

        this.detail.next(user);
    }
}
