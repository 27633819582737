import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-no-data-msg',
    template: 'Oh snap! Nothing to show here.',
})
export class NoDataMsgComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
