import { Pipe, PipeTransform } from "@angular/core";
import { getComponentViewDefinitionFactory } from "@angular/core/src/view";
import * as _ from 'lodash';

@Pipe({
    name: 'getSensorStyles'
})

// Returning a number between 0 and 1 that represents the percentage of moisture relative 
// to the sensors with the lowest (0) and highest (1) moisture readings.
// TODO: Need a fallback for situations in which highestVal = lowestVal, which would currently
// lead to a 0 in the denominator.
// TODO: Spread function "(...v)" isn't available with existing tslib. Upgrade seems to cause issues.
// Consider using a list instead of an array.

export class SensorStylesPipe implements PipeTransform {
    sensorValue(item, index: number) {
        let i = index + 1;
        let value = item.latest_log['moisture_' + i];
        return value;
    }

    transform(item, index: number) {
        let sensorValues: any[] = [];
        for (let i of [0,1,2,3,4,5,6,7,8]) {
            sensorValues.push(this.sensorValue(item, i));
        }
        let sortedValues = sensorValues.filter(value => value);
        sortedValues = sortedValues.slice().sort((a, b) => a - b);
        
        // console.log('sensorValues:', sensorValues);
        // console.log('sortedValues:', sortedValues);
        
        if ( sensorValues[index] == undefined || isNaN(sensorValues[index]) ) {
            return {'color': '#ffffff', 'background-color': '#666666'};
        } else {
            const minValue = sortedValues[0];
            const maxValue = sortedValues[sortedValues.length - 1];
            
            if (minValue !== maxValue) {
                let numerator = (sensorValues[index] - minValue);
                let denominator = (maxValue - minValue);
                let percentMoisture = numerator / denominator;
                let diff = 1 - percentMoisture;
                let valueCount = sortedValues.length;
                percentMoisture = percentMoisture + (diff/valueCount);
                
                const fontColor = percentMoisture > .75 ? '#ffffff' : '#000000';
                return {'color': fontColor, 'background-color': 'rgba(0, 157, 195,' + percentMoisture + ')'};
            } else {
                return {'color': '#000000', 'background-color': '#FFFFFF'};
            }
            
        }

    }
}