import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import linkifyStr from 'linkifyjs/string';


@Pipe({
    name: 'linkify',
    pure: true
})
@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {
    constructor(private sanitize: DomSanitizer) {
    }

    transform(str: string) {
        return this.sanitize.bypassSecurityTrustHtml(str ? linkifyStr(str, {target: '_system'}) : str);
    }
}
