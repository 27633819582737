import {AgmCoreModule} from "@agm/core";
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {ConfirmationPopoverModule} from "angular-confirmation-popover";
import {AngularResizedEventModule} from "angular-resize-event";
import {LaddaModule} from "angular2-ladda";
import {HighchartsChartModule} from "highcharts-angular";
import {BlockUIModule} from "ng-block-ui";
import {Daterangepicker} from 'ng2-daterangepicker';
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {
    AlertModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TooltipModule
} from "ngx-bootstrap";
import {NgxMaskModule} from "ngx-mask";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {NgxUploaderModule} from "ngx-uploader";
import {DatatableComponent} from "./components/datatable/datatable.component";
import {
    DatatableDirective,
    DatatableSortableDirective
} from "./components/datatable/datatable.directive";
import {FileUploaderModalComponent} from "./components/file-uploader/file-uploader-modal.component";
import {FileUploaderComponent} from './components/file-uploader/file-uploader.component';
import {FileUploaderService} from "./components/file-uploader/file-uploader.service";
import {FormErrorMsgComponent} from './components/form-error-msg/form-error-msg.component';
import {HeaderComponent} from "./components/header/header.component";
import {HeaderService} from "./components/header/header.service";
import {LeftMenuComponent} from "./components/left-menu/left-menu.component";
import {LeftMenuService} from "./components/left-menu/left-menu.service";
import {NoDataMsgComponent} from "./components/no-data-msg/no-data-msg.component";
import {OrganizationFormControlComponent} from './components/organization-form-control/organization-form-control.component';
import {OrganizationSubmitBtn} from "./components/organization-submit-btn/organization-submit-btn";
import {PageSizeSelectorComponent} from "./components/page-size-selector/page-size-selector.component";
import {DummyImageDirective} from './directives/dummy-image.directive';
import {ConstantLabelPipe} from "./pipes/constant-label.pipe";
import {GetPipe} from "./pipes/get.pipe";
import {LinkifyPipe} from "./pipes/linkify.pipe";
import {SensorStylesPipe} from "./pipes/sensor-styles.pipe";


@NgModule({
    imports:      [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        LaddaModule,
        Daterangepicker,
        PerfectScrollbarModule,
        BsDropdownModule,
        PaginationModule,
        TooltipModule,
        NgxUploaderModule,
        ProgressbarModule,
        ModalModule.forRoot(),
        NgSelectModule,
        ConfirmationPopoverModule,
        AlertModule.forRoot(),
    ],
    declarations: [
        FormErrorMsgComponent,
        LeftMenuComponent,
        HeaderComponent,

        GetPipe,
        ConstantLabelPipe,
        LinkifyPipe,
        DatatableComponent,
        DatatableDirective,
        DatatableSortableDirective,
        NoDataMsgComponent,
        PageSizeSelectorComponent,
        DummyImageDirective,

        FileUploaderModalComponent,
        FileUploaderComponent,
        OrganizationFormControlComponent,
        OrganizationSubmitBtn,
        SensorStylesPipe
    ],
    exports:      [
        PerfectScrollbarModule,
        RouterModule,
        BsDropdownModule,
        LaddaModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        NgxUploaderModule,
        ProgressbarModule,
        BlockUIModule,
        NgSelectModule,
        ConfirmationPopoverModule,
        PaginationModule,
        PerfectScrollbarModule,
        HighchartsChartModule,
        BsDatepickerModule,
        NgxMaskModule,
        AgmCoreModule,
        Daterangepicker,
        FormErrorMsgComponent,
        LeftMenuComponent,
        HeaderComponent,
        AlertModule,
        Ng2SearchPipeModule,

        DatatableComponent,
        DatatableDirective,
        DatatableSortableDirective,

        NoDataMsgComponent,
        PageSizeSelectorComponent,

        FileUploaderModalComponent,
        FileUploaderComponent,
        OrganizationFormControlComponent,
        OrganizationSubmitBtn,

        GetPipe,
        ConstantLabelPipe,
        LinkifyPipe,
        SensorStylesPipe,

        AngularResizedEventModule,

        DummyImageDirective,
       
    ],
    providers:       [FileUploaderService],
    entryComponents: [FileUploaderModalComponent]
})
export class SharedModule {
    static forSubRoot(): ModuleWithProviders {
        return {
            ngModule:  SharedModule,
            providers: [LeftMenuService, HeaderService]
        }
    }
}
