import {Directive, ElementRef, Input, OnInit} from '@angular/core';


interface DummyImageOptions {
    width: number;
    text: string;
    height: number;
}


@Directive({
    selector: '[appDummyImage]'
})
export class DummyImageDirective implements OnInit {

    @Input() appDummyImage: DummyImageOptions;

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.el.nativeElement.src = `https://dummyimage.com/${this.appDummyImage.width}x${this.appDummyImage.height}/000/fff&text=${this.appDummyImage.text}`;
    }


}
