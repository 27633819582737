import {Component, ElementRef, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {UploaderOptions, UploadInput, UploadOutput, UploadStatus} from "ngx-uploader";
import {Observable, Subject} from "rxjs";
import {CloudFile} from "../../../core/api-sdk/api-sdk.interface";
import {ApiSdkService} from "../../../core/api-sdk/api-sdk.service";
import {LoggerService} from "../../../core/logger.service";


@Component({
    selector:    'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls:   ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
    @Input() accept = '*';
    @Input() name = '';
    @ViewChild('fileUploader') fileUploader: ElementRef;

    options: UploaderOptions;
    uploadInput: EventEmitter<UploadInput>;
    uploadOutput: EventEmitter<UploadOutput>;

    private response: Subject<any>;

    constructor(private l: LoggerService,
                private api: ApiSdkService) {
    }

    private _files: Array<UploadOutput>;

    get files() {
        return this._files;
    }

    ngOnInit() {
        this.options = {concurrency: 1};
        this.uploadInput = new EventEmitter<UploadInput>();
        this.uploadOutput = new EventEmitter<UploadOutput>();
        this._files = [];

    }

    onUploadOutput(e: UploadOutput) {
        this.l.debug('UploadOutput', e);

        this.uploadOutput.emit(e);

        switch (e.type) {
            case 'addedToQueue':
                this._files = [];
                this._files.push(e);
                break;
            case 'uploading':
                break;
            case 'done':
                if (e.file.responseStatus == 201) {
                    this._files = [];
                    this.uploadInput.emit({type: 'removeAll'});
                    this.fileUploader.nativeElement.value = '';
                    this.name = '';
                    this.response.next({type: 'success', response: e.file.response});
                    this.response.complete();
                } else {
                    this.files[0].file.progress.status = UploadStatus.Queue;
                    this.response.next({type: 'error', response: e.file.response});
                    this.response.complete();
                }
                break;
            default:
                break;
        }
    }

    upload(target: string, objectId: string | number): Observable<{ body: CloudFile }> {
        return Observable.create(subscriber => {
            if (this.files.length == 0) {
                subscriber.next();
                subscriber.complete();
            }

            const header = this.api.http.getHeader(),
                data = _.omitBy({
                    target:    target,
                    object_id: objectId,
                    name:      this.name
                }, _.isNil);

            delete header['Content-Type'];

            const event: UploadInput = {
                type:    'uploadAll',
                url:     this.api.http.getUrl('/cloudfiles'),
                method:  'POST',
                data:    data,
                headers: header
            };

            this.uploadInput.emit(event);

            this.response = new Subject();

            this.response.subscribe(
                resp => {
                    if (resp.type == 'success') {
                        subscriber.next({body: resp.response.data})
                    } else {
                        subscriber.error({error: resp.response})
                    }

                    subscriber.complete();
                }
            )
        });
    }

}
