import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import * as _ from 'lodash';
import {Subject} from "rxjs/internal/Subject";
import {filter, takeUntil} from "rxjs/operators";


@Directive({
    selector: '[appDatatable]'
})
export class DatatableDirective implements OnInit {
    @Output() change: EventEmitter<any>;

    constructor() {
        this.change = new EventEmitter<any>();
    }

    ngOnInit(): void {

    }
}


@Directive({
    selector: '[appDatatableSortable]',
    host:     {'[class.c-pointer]': 'sortingKey', '[class.sorting]': 'sortingKey'}
})
export class DatatableSortableDirective implements OnInit, OnDestroy {
    @Input('appDatatableSortable') sortingKey: string;

    @HostBinding('class.sorting_asc') clsSortAsc: boolean;
    @HostBinding('class.sorting_desc') clsSortDesc: boolean;

    private state: string;
    private unsubscribe: Subject<void>;

    constructor(private datatable: DatatableDirective) {
        this.state = '';
        this.unsubscribe = new Subject<void>();
    }

    ngOnInit(): void {
        // Reset self state to non-sorting if other directive's sorting state is active
        this.datatable.change
            .pipe(filter(e => e.hasOwnProperty('sortBy')))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(e => {
                if (e.sortBy.indexOf(this.sortingKey) < 0) {
                    this.clsSortDesc = false;
                    this.clsSortAsc = false;
                    this.state = '';
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    @HostListener('click')
    onClick() {
        if (_.isEmpty(this.sortingKey)) return;

        if (this.state == '') {
            this.state = this.sortingKey;
            this.clsSortAsc = true;
            this.clsSortDesc = false;
        } else if (this.state == this.sortingKey) {
            this.state = `-${this.sortingKey}`;
            this.clsSortAsc = false;
            this.clsSortDesc = true;
        } else {
            this.state = '';
            this.clsSortAsc = false;
            this.clsSortDesc = false;
        }

        this.datatable.change.emit({sortBy: this.state});
    }
}
