import {Component, OnInit} from '@angular/core';


@Component({
    selector: '[app-page-size-selector]',
    template: `
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              `,
})
export class PageSizeSelectorComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
